/** 初始化手機選單 */
function initMobileNavMenu() {
  const $mobileMenuToggler = $('[data-mobile-menu-toggler]');
  const $mobileMenu = $('[data-mobile-menu]');
  const $siteHeader = $('.n1-site-header');

  function onMobileMenuTransitionEnd() {
    const state = $mobileMenu.attr('data-mobile-menu') === 'true';
    $mobileMenu.attr('data-mobile-menu', !state);
    if (state) {
      $siteHeader.removeClass('menu-shown');
    }
  }

  function showMobileMenu() {
    $('body').addClass('freeze-scrollbar');

    $siteHeader.addClass('menu-shown');
    $mobileMenu.animate({ height: 'show' }, 300, () => onMobileMenuTransitionEnd());
  }

  function closeMobileMenu() {
    $('body').removeClass('freeze-scrollbar');

    $mobileMenu.animate({ height: 'hide' }, 300, () => onMobileMenuTransitionEnd());
  }

  $mobileMenuToggler.on('click', () => {
    const state = $mobileMenu.attr('data-mobile-menu') === 'true';
    const nextState = !state;

    if (nextState) {
      showMobileMenu();
    } else {
      closeMobileMenu();
    }
  });

  // 點擊項目時，關閉選單
  // $('.n1-site-nav-menu-link, .n1-site-nav-menu-child-link').on('click', () => {
  //   closeMobileMenu();
  // });

  // 視窗尺寸改變時，關閉手機選單
  $(window).on('resize', () => {
    const menuState = $mobileMenu.attr('data-mobile-menu') === 'true';
    if (menuState) {
      closeMobileMenu();
    }
  });

  // 次選單收合
  const $collapseToggler = $('.n1-site-nav-menu-item-toggler');
  $collapseToggler.on('click', e => {
    e.preventDefault();

    const $self = $(e.currentTarget);

    const state = $self.attr('aria-expanded') === 'true';
    const nextState = !state;

    const $parentGroup = $self.parents('.n1-site-nav-menu-item');
    const $subMenu = $parentGroup.children('.n1-site-nav-menu-children-wrapper');

    if (nextState === true) {
      $parentGroup.addClass('show');
      $subMenu.addClass('show');
      $self.attr('aria-expanded', true);
    } else {
      $parentGroup.removeClass('show');
      $subMenu.removeClass('show');
      $self.attr('aria-expanded', false);
    }
    $subMenu.animate(
      {
        height: 'toggle',
      },
      300,
    );
  });
}

/** 初始化電腦版導航列搜尋 */
function initDeskNavSearch() {
  $(document).on('click', event => {
    const $clickTarget = $(event.target);
    const $clickToggler = $clickTarget.closest('[data-menu-toggler]');
    const $clickTogglerParent = $clickTarget.closest('.n1-site-desktop-actions-menu-item');
    // console.debug($clickTogglerParent, $clickTogglerParent.find('[data-menu-toggler]'));

    function _closeAllMenuToggler() {
      $('[data-menu-toggler]').each((i, el) => {
        const $self = $(el);
        const target = $self.attr('data-menu-toggler');

        if (target) {
          $(target).animate(
            {
              height: 'hide',
            },
            300,
            undefined,
            () => {
              $self.attr('aria-expanded', 'false');
            },
          );
        }
      });
    }

    if (!$clickToggler.length) {
      if ($clickTogglerParent.find('[data-menu-toggler]').length) {
        return;
      }

      // console.debug('點擊外部');
      _closeAllMenuToggler();

      return;
    }

    event.preventDefault();

    const toggleTargetName = $clickToggler.attr('data-menu-toggler');
    const $toggleTarget = $(toggleTargetName);
    const toggleState = $clickToggler.attr('aria-expanded') === 'true';
    const nextState = !toggleState;

    _closeAllMenuToggler();

    if (toggleState === true) {
      return;
    }

    if (toggleTargetName) {
      $toggleTarget.animate(
        {
          height: 'toggle',
        },
        300,
        undefined,
        () => {
          $clickToggler.attr('aria-expanded', nextState);
        },
      );
    }
  });
}

function checkNavbarStateAfterInited() {
  const $siteHeader = $('.n1-site-header');
  $siteHeader.toggleClass('page-scrolled', window.scrollY > 90);
}

/** 頁面捲動 */
function initPageScroll() {
  $(window).on('scroll', e => {
    const $siteHeader = $('.n1-site-header');
    const $body = $('body');
    $body.toggleClass('show-scroll-top', e.currentTarget.scrollY > window.innerHeight * 0.75);
    $siteHeader.toggleClass('page-scrolled', e.currentTarget.scrollY > 90);
  });
}

/**
 * 捲動至元素
 *
 * scrollToElement("#section-about");
 * scrollToElement(".page-tabs");
 */
function scrollToElement(elementSelector) {
  const offset = $('.n1-site-header').height();

  $('html, body').animate(
    {
      scrollTop: $(elementSelector).offset().top - offset,
    },
    300,
  );
}

/** 捲動頁面至頂部 */
function initScrollToTop() {
  $('[data-scrolltop]').on('click', e => {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 300);
  });
}

/** 初始化 AOS */
function initAOS() {
  AOS.init({
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'animate__animated', // class applied on animation
    useClassNames: true,
  });
}

function initSVGIcons() {
  $('.svg-icon').each((index, el) => {
    const iconName = $(el).attr('data-icon');

    if (iconName) {
      $(el).attr('role', 'img').attr('aria-label', iconName);
      const iconInner = $('<div class="icon-inner"></div>');
      const svg = $(`<svg class="svg-icon-body" aria-hidden="true"><use href="#n1_icon_${iconName}"></use></svg>`);
      iconInner.append(svg);
      $(el).append(iconInner);
    }
  });
}

function initCookieAgreementModal() {
  const result = sessionStorage.getItem('N1_SKIP_COOKIE_AGREEMENT_MODAL');
  let modal;

  if (result === null) {
    modal = new bootstrap.Modal('#agree-cookie-modal');
    modal.show();

    $('button[data-cookie-agreement]').on('click', e => {
      const $self = $(e.currentTarget);
      const agreement = $self.attr('data-cookie-agreement');

      if (agreement === 'accept') {
        sessionStorage.setItem('N1_SKIP_COOKIE_AGREEMENT_MODAL', agreement);
      }

      modal.hide();
    });
  }
}

function initScrollSpy() {
  const pageScrollSpyEl = document.querySelector('[data-bs-spy="scroll"]');

  if (!pageScrollSpyEl) {
    return;
  }

  pageScrollSpyEl.addEventListener('activate.bs.scrollspy', e => {
    const $self = $(e.relatedTarget);
    const theme = $self.attr('data-anchor-target-theme');
    const $parent = $self.closest('[data-anchor-theme]');

    $parent.attr('data-anchor-theme', theme);
  });
}

/** 初始化 Scroll To Top 按鈕變體
 * 指定白色按鈕的 section 要加上 [data-scroll-to-top-control="white"]
 */
function initScrollToTopButton() {
  const actionBtn$ = $('.n1-site-footer-scrolltop-button');

  $(window).on('scroll', () => {
    const sectionVariants = $('[data-scroll-to-top-control]');
    let currentSection = null;

    sectionVariants.each((index, section) => {
      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.offsetHeight;

      if (
        actionBtn$.offset().top + actionBtn$.height() * 0.5 >= sectionTop &&
        actionBtn$.offset().top + actionBtn$.height() * 0.5 < sectionBottom
      ) {
        currentSection = section;
      }
    });

    if (currentSection) {
      const variant = $(currentSection).attr('data-scroll-to-top-control');
      if (variant) {
        actionBtn$.addClass('n1-site-footer-scrolltop-button-white');
      } else {
        actionBtn$.removeClass('n1-site-footer-scrolltop-button-white');
      }
    } else {
      actionBtn$.removeClass('n1-site-footer-scrolltop-button-white');
    }
  });
}

$(function () {
  initMobileNavMenu();
  initDeskNavSearch();
  initPageScroll();
  initScrollToTop();
  initSVGIcons();
  checkNavbarStateAfterInited();
  initAOS();
  initScrollSpy();
  initCookieAgreementModal();
  initScrollToTopButton();
});
